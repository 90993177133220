import CartInterface from '@/interfaces/CartInterface';
import axios from 'axios';
import AjaxResponseInterface from '@/interfaces/AjaxResponseInterface';
import ValidationResponseInterface from '@/interfaces/ValidationResponseInterface';
import { getCookie } from '@/helpers';
import { mapCartResponse } from '@/classes/Product';

class OrderAPI {
  static validate(step: string, data: unknown): Promise<ValidationResponseInterface> {
    return new Promise<ValidationResponseInterface>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post<ValidationResponseInterface>(`${ajaxUrl}?action=order`, {
        endpoint: 'validate',
        step,
        data,
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as ValidationResponseInterface;

        resolve(ajaxResponseData);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static submit(data: unknown): Promise<ValidationResponseInterface> {
    return new Promise<ValidationResponseInterface>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post<ValidationResponseInterface>(`${ajaxUrl}?action=order`, {
        endpoint: 'submit',
        cartKey: getCookie('io_shop_cart'),
        data,
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as ValidationResponseInterface;

        resolve(ajaxResponseData);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getFormData(): Promise<Record<string, unknown>> {
    return new Promise<Record<string, unknown>>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.get<Record<string, unknown>>(ajaxUrl, {
        params: {
          action: 'order',
          endpoint: 'getFormData',
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;
        resolve(ajaxResponseData);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getLastOrderedItem(): Promise<Record<string, unknown>> {
    return new Promise<Record<string, unknown>>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.get<Record<string, unknown>>(ajaxUrl, {
        params: {
          action: 'order',
          endpoint: 'getLastOrderedItem',
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;
        resolve(ajaxResponseData);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static deleteLastOrderedItem(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post<Record<string, unknown>>(`${ajaxUrl}?action=order`, {
        endpoint: 'deleteLastOrderedItem',
      }).then(() => {
        resolve(true);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static activate(hash: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post<Record<string, unknown>>(`${ajaxUrl}?action=order`, {
        endpoint: 'activate',
        hash,
      }).then((response) => {
        if (response.status !== 200) {
          resolve(false);
        }
        resolve(true);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static storeIncompleteOrder(orderData: Record<any, unknown>, email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post<Record<string, unknown>>(`${ajaxUrl}?action=order`, {
        endpoint: 'storeIncomplete',
        orderData,
        email,
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;
        if (
          typeof ajaxResponseData.tokens === 'string'
          && ajaxResponseData.tokens.length > 0
        ) {
          resolve(ajaxResponseData.tokens);
        } else {
          reject(new Error('unable to store order'));
        }
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static validateIncompleteOrderToken(token: string): Promise<{
    cartData: CartInterface,
    cartEnabled: boolean,
    cartKey: string,
  }> {
    return new Promise((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post(`${ajaxUrl}?action=order`, {
        endpoint: 'validateIncompleteOrderToken',
        token,
      }).then((response) => {
        resolve({
          cartData: mapCartResponse(response),
          cartEnabled: (response?.data?.data?.cartEnabled || false) as boolean,
          cartKey: (response?.data?.data?.cartKey || '') as string,
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default OrderAPI;
